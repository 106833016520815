import Experience from "../components/Experience";
import Sidebar from "../sidebar/Sidebar";
import { mentalHealthWork } from "../data";
import Footer from "../components/Footer";

const MentalHealth = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <Sidebar page={"Mental Health Work"} />
        <div className="flex-1 pl-4 pr-8 py-4 lg:p-8 dark:bg-darkBg dark:text-darkText">
          <h1 className="text-lightText dark:text-darkText font-semibold tracking-wide text-3xl lg:text-4xl my-2">
            Mental Health Work
          </h1>
          {mentalHealthWork.map((item, index) => (
            <Experience
              key={index}
              organisation={item.organisation}
              role={item.role}
              duration={item.duration}
              jobscope={item.jobscope}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MentalHealth;

import { Link } from "react-router-dom";

const Project = ({ img, title, year, id, type }) => {
  return (
    <div className="mb-6 p-6 bg-lightBg dark:bg-darkBg border-2 border-opacity-25 dark:border-2 dark:border-opacity-25 border-lightText dark:border-darkText rounded-md">
      <img
        src={img}
        alt="Project Image"
        className="rounded-md h-64 w-full object-cover mb-4"
      />
      <div>
        <p className="text-md lg:text-lg font-semibold mb-1 text-lightText dark:text-darkText">
          {title}
        </p>
        <p className="text-xs lg:text-sm text-lightText dark:text-darkText mb-4">
          {year}
        </p>
        <Link to={type === "Research" ? `/research/${id}` : `/projects/${id}`}>
          <button className="px-4 py-2 rounded bg-darkBg text-darkText dark:bg-lightBg dark:text-lightText hover:bg-green hover:text-white dark:hover:bg-green dark:hover:text-white text-sm lg:text-base">
            View More!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Project;
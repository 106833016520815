import Experience from "../components/Experience";
import Sidebar from "../sidebar/Sidebar";
import { fifthrows, onlinecourses, education } from "../data";
import Footer from "../components/Footer";

const Education = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <Sidebar page={"Education"} />
        <div className="flex-1 pl-4 pr-8 py-4 lg:p-8 dark:bg-darkBg dark:text-darkText">
          <h1 className="text-lightText dark:text-darkText font-semibold tracking-wide text-3xl lg:text-4xl my-2">
            Education
          </h1>

          <section>
            <h2 className="text-green font-semibold tracking-wide text-2xl lg:text-3xl mt-3 lg:mt-5 mb-1 lg:mb-2">
              Schools
            </h2>
            <div className="mt-3 lg:mt-5 bg-lightBg text-lightText dark:bg-darkBg dark:text-darkText">
              {education.map((item, index) => (
                <Experience
                  key={index}
                  organisation={item.organisation}
                  role={item.role}
                  duration={item.duration}
                  jobscope={item.jobscope}
                  repeat={item.repeat}
                />
              ))}
            </div>
          </section>

          <section>
            <h2 className="text-green font-semibold tracking-wide text-2xl lg:text-3xl mt-5 mb-2">
              Fifth Rows/Co-Curricular Activities
            </h2>
            {fifthrows.map((item, index) => (
              <Experience
                key={index}
                organisation={item.organisation}
                role={item.role}
                duration={item.duration}
                jobscope={item.jobscope}
                repeat={item.repeat}
              />
            ))}
          </section>

          {/* <section>
          <h2 className="text-green font-semibold tracking-wide text-2xl lg:text-3xl mt-10 mb-2">
            Online Courses
          </h2>
          <ol className="list-decimal break-normal">
            {onlinecourses.map((item, index) => (
              <li key={index} className="mb-4">
                <p className="text-xl font-medium mt-2 underline">
                  {item.title}
                </p>
                <div className="flex items-center mb-1">
                  <p className="text-base lg:text-lg flex-1">
                    {item.organisation} | {item.platform}
                  </p>
                  <p className="text-sm lg:text-base">{item.year}</p>
                </div>
                {item.description && (
                  <div className="text-sm lg:text-base">
                    <li>{item.description}</li>
                  </div>
                )}
                <a
                  href={item.credential}
                  target="_blank"
                  className="hover:underline hover:font-semibold text-green text-sm lg:text-base mt-2"
                >
                  View Credential
                </a>
              </li>
            ))}
          </ol>
        </section> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Education;

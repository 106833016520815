import Sidebar from "../sidebar/Sidebar";
import Footer from "../components/Footer";
import Award from "../components/Award";
import { awards, certifications } from "../data";
import kellie1Img from "../images/kellie1.JPG";
import kellie2Img from "../images/kellie2.JPG";
import kellie3Img from "../images/kellie3.JPG";
import kellie4Img from "../images/kellie4.jpeg";

const About = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <Sidebar page={"About"} />
        <main className="flex-1 pl-4 pr-8 py-4 lg:p-8 dark:bg-darkBg dark:text-darkText">
          <h1 className="text-3xl lg:text-4xl font-semibold text-lightText dark:text-darkText">
            About Me
          </h1>

          <section className="mt-8">
            <h2 className="text-2xl lg:text-3xl font-semibold text-lightText dark:text-darkText">
              Biography
            </h2>
            <p className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText">
              Hello! My name is Kellie, or sometimes known as ‘kelliepanda’ on
              the Internet. I am a PhD student at Singapore University of
              Technology and Design (SUTD), specialising in Human-AI Interaction
              and Mental Health. My research focuses on leveraging AI to support
              mental health and enhance user experiences with empathetic and
              supportive human-AI interactions.A side interest of mine is in the
              area of neurodiversity, and how technology and neurodiversity can
              intersect to create more inclusive and supportive environments.
            </p>
            <p className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText">
              I completed my undergraduate studies at SUTD, where I graduated
              with a Bachelor of Engineering (Computer Science and Design). I
              have been actively involved in student life and campus activities
              during my time at SUTD. I have held multiple leadership positions
              in student clubs and societies, and have been actively involved in
              community service and volunteer work. I also worked on several
              research projects during my undergraduate studies, where I gained
              experience in conducting research and writing research papers.
            </p>

            <p className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText">
              Outside of school, I was also involved in the local mental health
              scene, such as by being an avid advocate on my Instagram page (
              <a
                href="https://instagram.com/kelliepandasg"
                target="_blank"
                and
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                @kelliepandasg
              </a>
              ), a Community Peer Supporter under Youth Corps Singapore's{" "}
              <a
                href="https://linktr.ee/projectreground"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Project Re:ground
              </a>
              , a Mindline Youth Fellow under{" "}
              <a
                href="https://mindline.sg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                mindline.sg
              </a>
              , working at a local mental health startup (
              <a
                className="text-green hover:underline"
                href="https://kurakura.io/"
                target="_blank"
                and
                rel="noopener noreferrer"
              >
                Kura Kura
              </a>
              ), and through various other projects.
            </p>
            <p className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText">
              My goal is to combine my technical skills with my passion for
              social impact to create technology that makes a positive
              difference in people’s lives.
            </p>
          </section>

          <section className="mt-12">
            <h2 className="text-3xl font-semibold text-lightText dark:text-darkText">
              Pictures of Me
            </h2>
            <p className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText">
              I couldn't decide which picture to use, so here are a few of them!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-8 mt-3 lg:mt-5">
              <div className="mb-6 p-6 bg-lightBg dark:bg-darkBg border-2 border-opacity-25 dark:border-2 dark:border-opacity-25 border-lightText dark:border-darkText rounded-md">
                <img
                  src={kellie1Img}
                  alt="Picture of me at Phillip Burton Wilderness, Inverness, California"
                  className="rounded-lg mb-4"
                />
                <p className="text-xs lg:text-sm leading-relaxed text-lightText dark:text-darkText">
                  Taken near Point Reyes on one of the road trips my friends and
                  I took while on exchange in University of California,
                  Berkeley! This was at Phillip Burton Wilderness, Inverness,
                  California.
                </p>
              </div>
              <div className="mb-6 p-6 bg-lightBg dark:bg-darkBg border-2 border-opacity-25 dark:border-2 dark:border-opacity-25 border-lightText dark:border-darkText rounded-md">
                <img
                  src={kellie2Img}
                  alt="Picture of me at Gaomei Wetlands, Taichung, Taiwan"
                  className="rounded-lg mb-4"
                />
                <p className="text-xs lg:text-sm leading-relaxed text-lightText dark:text-darkText">
                  Taken near Gaomei Wetlands (高美溼地) in Taichung, Taiwan
                  during a family trip. Enjoyed my red hair era!
                </p>
              </div>
              <div className="mb-6 p-6 bg-lightBg dark:bg-darkBg border-2 border-opacity-25 dark:border-2 dark:border-opacity-25 border-lightText dark:border-darkText rounded-md">
                <img
                  src={kellie3Img}
                  alt="Picture of me at Seoul Forest, Seoul, South Korea"
                  className="rounded-lg mb-4"
                />
                <p className="text-xs lg:text-sm leading-relaxed text-lightText dark:text-darkText">
                  Taken at Seoul Forest in Seoul, South Korea during a grad
                  trip. I really love looking at daisies! And my hair was a nice
                  grey here.
                </p>
              </div>
              <div className="mb-6 p-6 bg-lightBg dark:bg-darkBg border-2 border-opacity-25 dark:border-2 dark:border-opacity-25 border-lightText dark:border-darkText rounded-md">
                <img
                  src={kellie4Img}
                  alt="Picture of me at the Inside Out 2 exhibition at Gardens by the Bay, Singapore"
                  className="rounded-lg mb-4"
                />
                <p className="text-xs lg:text-sm leading-relaxed text-lightText dark:text-darkText">
                  Taken at the Inside Out 2 exhibition at Gardens by the Bay,
                  Singapore. I love how Inside Out showcases our different
                  emotions and highlights how they're all important in making us
                  who we are. This picture features the Kaleidoscope of Memories
                  installation, which I enjoyed as I hold memories (no matter
                  what emotions they may be associated with!) very dear to me.
                </p>
              </div>
            </div>
          </section>
          {/* <section className="mt-12">
            <h2 className="text-3xl font-semibold text-lightText dark:text-darkText">
              Skills & Expertise
            </h2>
            <ul className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText list-disc list-inside">
              <li>
                Technical: Breadboard Fabrication and Testing, Microcontroller
                Programming and Interfacing
              </li>
              <li>
                Programming: Python, HTML, Arduino, JavaScript, CSS, C, C#, C++,
                Java, React, React Native, MATLAB
              </li>
              <li>Languages: English, Chinese, Korean</li>
              <li>Business: China Readiness, Social Innovation</li>
              <li>Design: Design Innovation, Canva, Figma</li>
            </ul>
          </section> */}
          <section className="mt-12">
            <h2 className="text-3xl font-semibold text-lightText dark:text-darkText">
              Personal Interests
            </h2>
            <p className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText">
              Outside of my academic pursuits, I enjoy(ed) ice-skating and
              inline skating. I'm also trying to get back into reading. I enjoy
              listening to music (especially K-pop). There's a high chance I'll
              be found at a concert or a music festival if there's one
              happening.
            </p>
          </section>
          <section className="mt-12">
            <h2 className="text-3xl font-semibold text-lightText dark:text-darkText">
              Contact Information
            </h2>
            <p className="mt-4 text-base lg:text-md leading-relaxed text-lightText dark:text-darkText">
              Feel free to reach out to me via email at{" "}
              <a
                href="mailto:kelliesyhh@gmail.com"
                className="text-green hover:underline"
              >
                kelliesyhh@gmail.com
              </a>{" "}
              or connect with me on{" "}
              <a
                href="https://www.linkedin.com/in/kelliesim"
                className="text-green hover:underline"
              >
                LinkedIn
              </a>
              .
            </p>
          </section>

          {/* <section className="mt-12">
      <h2 className="text-3xl font-semibold text-lightText dark:text-darkText">
        Awards
      </h2>
      {awards.map((item, index) => (
        <Award
          key={index}
          organisation={item.organisation}
          award={item.award}
          duration={item.duration}
          jobscope={item.jobscope}
        />
      ))}
    </section>

    <section className="mt-12">
      <h2 className="text-3xl font-semibold text-lightText dark:text-darkText">
        Certifications
      </h2>
      <ol className="list-decimal break-normal">
        {certifications.map((item, index) => (
          <li key={index} className="mb-4">
            <p className="text-xl font-medium mt-2">{item.title}</p>
            <div className="flex items-center mb-1">
              <p className="text-base lg:text-md flex-1">
                {item.organisation}
              </p>
              <p className="text-sm lg:text-base">{item.year}</p>
            </div>
            <a
              href={item.credential}
              target="_blank"
              className="hover:underline hover:font-semibold text-green text-sm lg:text-base mt-2"
            >
              View Credential
            </a>
          </li>
        ))}
      </ol>
    </section> */}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default About;

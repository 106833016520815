import React from "react";

const Experience = ({ organisation, role, duration, jobscope }) => {
  return (
    <div className="my-3 lg:my-6 p-6 bg-lightBg dark:bg-darkBg border-2 border-opacity-25 dark:border-2 dark:border-opacity-25 border-lightText dark:border-darkText rounded-md">
      <p className="text-lg font-semibold mb-1 text-lightText dark:text-darkText">
        {organisation}
      </p>
      <div className="flex items-center mb-2">
        <p className="text-base font-medium text-green flex-1">{role}</p>
      </div>
      <div className="flex items-center mb-2">
        <p className="text-sm text-lightText dark:text-darkText">{duration}</p>
      </div>
      <ul className="list-disc pl-5 text-sm text-lightText dark:text-darkText">
        {jobscope.map((item, index) => (
          <li key={index}>
            {item.description.includes("<a") ? (
              <span dangerouslySetInnerHTML={{ __html: item.description }} />
            ) : (
              item.description
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Experience;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { researchProjects, schoolProjects, otherProjects } from "../data";
import Carousel from "./Carousel";
import { FaArrowCircleLeft } from "react-icons/fa";

const ProjectViewMore = () => {
  const params = useParams();
  const [projectData, setProjectData] = useState(null);
  const [isResearch, setIsResearch] = useState(false);
  const [isOther, setIsOther] = useState(false);

  useEffect(() => {
    let project = researchProjects.find((project) => project.id === params.id);
    if (!project) {
      project = schoolProjects.find((project) => project.id === params.id);
      if (!project) {
        project = otherProjects.find((project) => project.id === params.id);
        setIsOther(true);
      }
    } else {
      setIsResearch(true);
    }
    setProjectData(project);
  }, [params.id]);

  if (!projectData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        Project not found
      </div>
    );
  }

  const {
    title,
    type,
    img,
    carouselData,
    description,
    github,
    report,
    youtube,
  } = projectData;
  
  const newText = description
    ? description.split("\n\n").map((block, i) => (
        <div key={i}>
          {block.split("\n").map((line, j) =>
            line.includes("<") ? (
              <div key={j} dangerouslySetInnerHTML={{ __html: line }}></div>
            ) : (
              <p className="mt-2" key={j}>
                {line}
              </p>
            )
          )}
          {i < description.split("\n\n").length - 1 && <br />}
        </div>
      ))
    : null;

  return (
    <div className="flex items-center justify-center min-h-screen bg-lightBg dark:bg-darkBg p-4">
      <div className="w-full max-w-4xl mx-auto bg-white dark:bg-darkBg border-2 border-opacity-25 dark:border-2 dark:border-opacity-25 border-lightText dark:border-darkText rounded-md p-6 shadow-lg">
        <Link to={isResearch ? "/research" : "/projects"} className="text-green text-3xl lg:text-4xl">
          <FaArrowCircleLeft />
        </Link>
        <h1 className="text-xl lg:text-2xl text-green font-semibold mt-4 mb-2">{title}</h1>
        <div className="text-sm lg:text-base text-lightText dark:text-darkText mb-4">{newText}</div>
        {carouselData.length > 0 && (
          <div className="self-center my-6">
            <Carousel carouselData={carouselData}></Carousel>
          </div>
        )}
        {youtube && (
          <div className="aspect-w-16 aspect-h-9 mb-6">
            <iframe
              src={youtube}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
              className="w-full rounded-md"
            ></iframe>
          </div>
        )}
        <div className="flex flex-wrap mt-8">
          {github && (
            <a href={github} target="_blank" rel="noopener noreferrer" className="mr-4 mb-4">
              <button className="rounded bg-darkBg text-darkText dark:bg-lightBg dark:text-lightText hover:bg-green py-2 px-4 hover:bg-green hover:text-white dark:hover:bg-green dark:hover:text-white text-sm lg:text-base">
                View GitHub Repository!
              </button>
            </a>
          )}
          {report && (
            <a href={report} target="_blank" rel="noopener noreferrer" className="mr-4 mb-4">
              <button className="rounded bg-darkBg text-darkText dark:bg-lightBg dark:text-lightText hover:bg-green py-2 px-4 hover:bg-green hover:text-white dark:hover:bg-green dark:hover:text-white text-sm lg:text-base">
                Read More!
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectViewMore;
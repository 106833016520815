import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Research from "./pages/Research";
import Projects from "./pages/Projects";
import { Route, Routes } from "react-router-dom";
import ProjectViewMore from "./components/ProjectViewMore";
import Work from "./pages/Work";
import Education from "./pages/Education";
import MentalHealth from "./pages/MentalHealth";
import { DarkModeProvider } from "./components/DarkModeContext";

function App() {
  return (
    <DarkModeProvider>
      <div className="max-w-full bg-lightBg dark:bg-darkBg">
        <Routes>
          <Route exact path="" element={<Home />} />
          <Route exact path="home" element={<Home />} />
          <Route exact path="about" element={<About />} />
          <Route exact path="research" element={<Research />} />
          <Route exact path="research/:id" element={<ProjectViewMore />} />

          <Route exact path="projects" element={<Projects />} />
          <Route exact path="projects/:id" element={<ProjectViewMore />} />
          <Route
            exact
            path="projects/undefined"
            element={
              <main className="h-screen p-2">
                <p>There's nothing here!</p>
              </main>
            }
          />
          <Route exact path="work" element={<Work />} />
          <Route exact path="education" element={<Education />} />
          <Route exact path="mental-health" element={<MentalHealth />} />
          <Route
            path="*"
            element={
              <main className="h-screen p-2">
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </div>
    </DarkModeProvider>
  );
}

export default App;

import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Swipe from "react-easy-swipe";

const Carousel = ({ carouselData }) => {
  const [paused, setPaused] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  if (carouselData.length === 0) {
    return null; // Don't render anything if carouselData is empty
  }

  const nextSlide = () => {
    let newSlide =
      currentSlide === carouselData.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(newSlide);
  };

  const prevSlide = () => {
    let newSlide =
      currentSlide === 0 ? carouselData.length - 1 : currentSlide - 1;
    setCurrentSlide(newSlide);
  };

  return (
    <div>
      <div className="h-108 flex overflow-hidden justify-center relative">
        {carouselData.length > 1 && (
          <AiOutlineLeft
            onClick={prevSlide}
            className="absolute left-0 text-2xl lg:text-3xl inset-y-1/2 text-darkText cursor-pointer"
          />
        )}

        <Swipe onSwipeLeft={nextSlide} onSwipeRight={prevSlide}>
          {carouselData.map((slide, index) => (
            <img
              src={slide.image}
              alt="This is a carousel slide"
              key={index}
              className={
                index === currentSlide
                  ? "block h-full w-auto object-cover"
                  : "hidden"
              }
              onMouseEnter={() => setPaused(true)}
              onMouseLeave={() => setPaused(false)}
            />
          ))}
        </Swipe>

        {carouselData.length > 1 && (
          <div className="absolute w-full flex justify-center bottom-0">
            {carouselData.map((_, index) => (
              <div
                className={
                  index === currentSlide
                    ? "h-2 w-2 bg-grey rounded-full mx-2 mb-2 cursor-pointer"
                    : "h-2 w-2 bg-white rounded-full mx-2 mb-2 cursor-pointer"
                }
                key={index}
                onClick={() => setCurrentSlide(index)}
              ></div>
            ))}
          </div>
        )}

        {carouselData.length > 1 && (
          <AiOutlineRight
            onClick={nextSlide}
            className="absolute right-0 text-2xl lg:text-3xl inset-y-1/2 text-darkText cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default Carousel;
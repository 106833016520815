import { Link } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../components/Footer";
import KellieLogo from "../navbar/kellie_logo.png";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <Sidebar page={"Home"} />
        <main className="flex-1 pl-4 pr-8 py-4 lg:p-8 dark:bg-darkBg dark:text-darkText">
          <section className="text-left">
            <img
              className="h-32 w-32 rounded-full"
              src={KellieLogo}
              alt="Kellie Sim"
            />
            <h1 className="text-3xl lg:text-4xl font-bold mt-4 text-lightText dark:text-darkText">
              Kellie Sim
            </h1>
            <p className="text-lg lg:text-xl mt-2 text-green">
              PhD Student at Singapore University of Technology and Design
              (SUTD)
            </p>
          </section>
          <section className="mt-12">
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              Hello! My name is Kellie, or sometimes known as ‘kelliepanda’ on
              the Internet. I am a PhD student at Singapore University of
              Technology and Design (SUTD), specialising in Human-AI Interaction
              and Mental Health. My research focuses on leveraging AI to support
              mental health and enhance user experiences with empathetic and
              supportive human-AI interactions. A side interest of mine is in
              the area of neurodiversity, and how technology and neurodiversity
              can intersect to create more inclusive and supportive
              environments.
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              I completed my undergraduate studies at SUTD, where I graduated
              with a Bachelor of Engineering (Computer Science and Design). I
              have been actively involved in student life and campus activities
              during my time at SUTD. I have held multiple leadership positions
              in student clubs and societies, and have been actively involved in
              community service and volunteer work. I also worked on several
              research projects during my undergraduate studies, where I gained
              experience in conducting research and writing research papers.
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              Outside of school, I was also involved in the local mental health
              scene, such as by being an avid advocate on my Instagram page (
              <a
                href="https://instagram.com/kelliepandasg"
                target="_blank"
                and
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                @kelliepandasg
              </a>
              ), a Community Peer Supporter under Youth Corps Singapore's{" "}
              <a
                href="https://linktr.ee/projectreground"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Project Re:ground
              </a>
              , a Mindline Youth Fellow under{" "}
              <a
                href="https://mindline.sg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                mindline.sg
              </a>
              , working at a local mental health startup (
              <a
                className="text-green hover:underline"
                href="https://kurakura.io/"
                target="_blank"
                and
                rel="noopener noreferrer"
              >
                Kura Kura
              </a>
              ), and through various other projects.
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              My goal is to combine my technical skills with my passion for
              social impact to create technology that makes a positive
              difference in people’s lives.
            </p>
          </section>

          <section className="mt-12 text-left">
            <Link to="/about">
              <button className="bg-green px-10 py-2 rounded-xl text-lightText transition-transform transform hover:scale-105">
                Learn More
              </button>
            </Link>
          </section>

          <section className="mt-12 text-left">
            <h2 className="text-3xl font-bold text-lightText dark:text-darkText">
              News and Updates
            </h2>
            <ul className="list-disc mt-4 text-lg text-lightText dark:text-darkText">
              <li className="mb-2">
                September 2024: Starting my PhD journey at SUTD!
              </li>
              <li className="mb-2">
                July 2024: Attended my first conference -{" "}
                <a
                  href="https://dis.acm.org/2024/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline
                "
                >
                  ACM Designing Interactive Systems (DIS) 2024
                </a>
                ! Presented our work on "Towards Understanding Emotions for
                Engaged Mental Health Conversations (
                <a
                  href="https://doi.org/10.1145/3656156.3663694"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  link
                </a>
                ).
              </li>
              <li className="mb-2">
                May 2024: Graduated from SUTD with a Bachelor of Engineering in
                Computer Science and Design!
              </li>
            </ul>
          </section>

          <section className="mt-12 text-left">
            <h2 className="text-3xl font-bold text-lightText dark:text-darkText">
              Media Features
            </h2>
            <ul className="mt-4 text-lg text-lightText dark:text-darkText">
              <li className="mb-2">
                <span className="font-semibold">[12 April 2024] </span>
                <span className="font-normal">
                  新科大设计医院智能导航系统 助年长者看病凭脚印不迷路
                </span>
                <br />
                <a
                  href="https://www.zaobao.com.sg/news/singapore/story20240412-3394837"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline mr-2"
                >
                  Lianhe Zaobao (Original Article){" "}
                </a>
                |{" "}
                <a
                  href="https://www.sutd.edu.sg/About/happenings/News/2024/4/SUTD-designs-a-smart-navigation-system-that-uses-s"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-green hover:underline"
                >
                  English Translation
                </a>
              </li>
              <li className="mb-2">
                <span className="font-semibold">[13 July 2022] </span>
                <span className="font-normal">设IG账号宣导心理健康</span>
                <br />
                <a
                  href="https://www.zaobao.com.sg/news/singapore/story20220713-1292247"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline mr-2"
                >
                  Lianhe Zaobao (Original Article)
                </a>{" "}
                |{" "}
                <a
                  href="https://www.sutd.edu.sg/About/happenings/News/2022/7/Using-IG-to-promote-mental-wellness"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-green hover:underline"
                >
                  English Translation
                </a>
              </li>
            </ul>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Home;

import Project from "../components/Project";
import Sidebar from "../sidebar/Sidebar";
import { researchProjects, publications } from "../data";
import Footer from "../components/Footer";

const Research = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <Sidebar page={"Research"} />
        <div className="flex-1 pl-4 pr-8 py-4 lg:p-8 bg-lightBg text-lightText dark:bg-darkBg dark:text-darkText">
          <h1 className="text-lightText dark:text-darkText font-semibold tracking-wide text-3xl lg:text-4xl my-2">
            Research
          </h1>
          <section>
            <h2 className="text-green font-semibold tracking-wide text-2xl lg:text-3xl mt-3 lg:mt-5 mb-1 lg:mb-2">
              Publications
            </h2>
            {publications.map((item) => (
              <div className="py-3" key={item.id}>
                <span dangerouslySetInnerHTML={{ __html: item.value }} />
                <br />
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline hover:font-semibold text-green text-sm lg:text-base mt-2"
                >
                  View Publication
                </a>
                {item.pdf && (
                  <a
                    href={item.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline hover:font-semibold text-green text-sm lg:text-base mt-2 ml-4"
                  >
                    View PDF
                  </a>
                )}
              </div>
            ))}
          </section>
          <section>
            <h2 className="text-green font-semibold tracking-wide text-2xl lg:text-3xl mt-3 lg:mt-5 mb-1 lg:mb-2">
              Undergraduate Research Projects
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-8 mt-3 lg:mt-5">
              {researchProjects.map((item) => (
                <Project
                  key={item.id}
                  id={item.id}
                  img={item.img}
                  title={item.title}
                  year={item.year}
                  description={item.description}
                  github={item.github}
                  type="Research"
                />
              ))}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Research;

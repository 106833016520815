import Project from "../components/Project";
import Sidebar from "../sidebar/Sidebar";
import { schoolProjects, otherProjects } from "../data";
import Footer from "../components/Footer";

const Projects = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <Sidebar page={"Projects"} />
        <div className="flex-1 pl-4 pr-8 py-4 lg:p-8 bg-lightBg text-lightText dark:bg-darkBg dark:text-darkText">
          <h1 className="text-lightText dark:text-darkText font-semibold tracking-wide text-3xl lg:text-4xl my-2">
            Projects
          </h1>

          <section>
            <h2 className="text-green font-semibold tracking-wide text-2xl lg:text-3xl mt-3 lg:mt-5 mb-1 lg:mb-2">
              School Projects
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-8 mt-3 lg:mt-5">
              {schoolProjects.map((item) => (
                <Project
                  key={item.id}
                  id={item.id}
                  img={item.img}
                  title={item.title}
                  year={item.year}
                  description={item.description}
                  github={item.github}
                  type="School"
                />
              ))}
            </div>
          </section>

          <section>
            <h2 className="text-green font-semibold tracking-wide text-2xl lg:text-3xl mt-5 mb-2">
              Other Projects
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-5">
              {otherProjects.map((item) => (
                <Project
                  key={item.id}
                  id={item.id}
                  img={item.img}
                  title={item.title}
                  year={item.year}
                  description={item.description}
                  github={item.github}
                  type="Others"
                />
              ))}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Projects;
